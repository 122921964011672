import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import { useArchiveWorkflow } from '@/queries/useArchiveWorkflow';
import useDeleteWorkflow from '@/queries/useDeleteWorkflow';
import { useModalsStorage } from '@/stores/modals';
import { useWorkflowsStorage } from '@/stores/workflows';

import { ActionModals } from '@/types';

const useWorkflowsModals = () => {
  const { deleteWorkflow, isDeletingWorkflow } = useDeleteWorkflow();
  const { archiveWorkflow, isArchiveWorkflow } = useArchiveWorkflow();
  const { setIsModalOpen } = useModalsStorage();
  const { currentWorkflow, setCurrentWorkflow } = useWorkflowsStorage();
  const { t } = useTranslation('common');

  const modals: ActionModals = {
    DELETE_WORKFLOW: {
      children: (
        <CommonModalLabel
          leadingText={t('delete_unarchive_workflow_confirm_before')}
          redText={t('delete_unarchive_workflow_confirm_red_text')}
          trailingText={t('delete_unarchive_workflow_confirm_after')}
        />
      ),
      isSubmitting: isDeletingWorkflow,
      onConfirm: () => {
        if (currentWorkflow) {
          deleteWorkflow(currentWorkflow.id);
        }
      },
      onClose: () => {
        setIsModalOpen(false);
        setCurrentWorkflow();
      },
    },
    UNABLE_DELETE: {
      children: (
        <label className='flex flex-col items-center text-sm'>
          <span className='mr-2 text-red-500'>
            {t('unable_to_delete_workflow_contains_certification_red')}
          </span>
          <span>{t('unable_to_delete_workflow_contains_certification')}</span>
        </label>
      ),
      closeText: 'Ok',
    },
    ARCHIVE_WORKFLOW: {
      children: (
        <CommonModalLabel redText={t('archive_workflow_confirm_red_text')} />
      ),
      onConfirm: () => {
        if (currentWorkflow) {
          archiveWorkflow(currentWorkflow.id);
        }
      },
      isSubmitting: isArchiveWorkflow,
      onClose: () => {
        setCurrentWorkflow();
      },
    },
    READONLY_WORKFLOW: {
      children: (
        <label className='flex flex-col items-center text-center text-sm'>
          {t('workflows-page.read-only-modal')}
        </label>
      ),
    },
    CLIENT_ERROR: {
      children: t('client-save-error'),
      closeText: 'Ok',
    },
  };
  return {
    modals,
  };
};

export default useWorkflowsModals;
