import useTranslation from 'next-translate/useTranslation';

import { useFormStorage } from '@/stores/forms';
import { useModalsStorage } from '@/stores/modals';

import { ActionModals } from '@/types';

const useFormEditorModals = () => {
  const { savedTemplate } = useFormStorage();
  const { setIsModalOpen } = useModalsStorage();
  const { t } = useTranslation('common');

  const { setBuilderState } = useFormStorage();

  const modals: ActionModals = {
    DISCARD_FORM: {
      children: (
        <div className='flex flex-col items-center'>
          <p>
            {t('confirm_message')}
            <span className='text-red-500'>{t('discard-message')}</span>?
          </p>
        </div>
      ),
      onConfirm: () => {
        setBuilderState(savedTemplate);
        setIsModalOpen(false);
      },
      onClose: () => {
        setIsModalOpen(false);
      },
    },
  };

  return {
    modals,
  };
};

export default useFormEditorModals;
