import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useModalsStorage } from '@/stores/modals';
import { useWorkflowsStorage } from '@/stores/workflows';

const archiveWorkflow = async (workflowId: number) => {
  const { data } = await api.post(
    API_ENDPOINTS.WORKFLOW_ARCHIVE(workflowId ?? null)
  );

  return data;
};

export function useArchiveWorkflow() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setCurrentWorkflow } = useWorkflowsStorage();
  const { setIsModalOpen } = useModalsStorage();
  const { mutate, isLoading, status, isSuccess, reset } = useMutation(
    archiveWorkflow,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workflows');
        toast.success(t('workflows-page.archive-success'));
        setIsModalOpen(false);
      },
      onError: () => {
        toast.error(t('workflows-page.archive-error'));
      },
      onSettled: () => {
        setCurrentWorkflow();
      },
    }
  );

  return {
    isArchiveWorkflow: isLoading,
    archiveWorkflow: mutate,
    archiveWorkflowStatus: status,
    archiveWorkflowSuccessfully: isSuccess,
    resetArchive: reset,
  };
}
