import useTranslation from 'next-translate/useTranslation';

import { ActionModals } from '@/types';

const useFileModals = () => {
  const { t: tv } = useTranslation('verifier');

  const modals: ActionModals = {
    WRONG_FILE_FORMAT: {
      children: (
        <div className='flex flex-col text-center'>
          <p className='text-md mb-2 font-semibold'>
            {tv('wrong-file-format-title')}
          </p>
          <label className='inline-block text-sm'>
            {tv('wrong-file-format-description')}
          </label>
        </div>
      ),
    },
  };
  return {
    modals,
  };
};

export default useFileModals;
