/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import create, { State as ZustandState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { buildDefaultStepState } from '@/utils/webForm';

import {
  ClaimInterface,
  Inspection,
  TemplateInterface,
  WorkflowInterface,
} from '@/types';

interface WebInspectionsStorageStateInterface extends ZustandState {
  // Values
  inspection?: Inspection;
  task: WorkflowInterface | ClaimInterface | undefined;
  currentStep: number;
  currentSectionStep: number;
  form: any;
  normalizedForm: any;
  draftSchema: any;
  completed: boolean;
  stopNavigation: boolean;
  isProgressive: boolean;
  isLoadingForm: boolean;
  // Setters
  setIsLoadingForm: (isLoadingForm: boolean) => void;
  setIsProgressive: (isProgressive: boolean) => void;
  setStopNavigation: (stopNavigation: boolean) => void;
  setDraftSchema: (draftSchema?: any) => void;
  setInspection: (inspection?: Inspection) => void;
  setCurrentSectionStep: (currentSectionStep: number) => void;
  setCurrentStep: (currentStep: number) => void;
  setTask: (task?: ClaimInterface | WorkflowInterface | undefined) => void;
  setForm: (form?: any) => void;
  setNormalizedForm: (normalizedForm?: any) => void;
  resetStore: () => void;
  setCompleted: (completed: boolean) => void;
}

const defaultValues = {
  task: undefined,
  inspection: undefined,
  currentStep: 1,
  currentSectionStep: 0,
  normalizedForm: undefined,
  form: undefined,
  draftSchema: undefined,
  completed: false,
  stopNavigation: false,
  isProgressive: false,
  isLoadingForm: true,
};

export const useWebInspectionStorage =
  create<WebInspectionsStorageStateInterface>(
    persist(
      devtools((set) => ({
        ...defaultValues,
        setIsProgressive: (isProgressive) =>
          set({
            isProgressive,
          }),
        setStopNavigation: (stopNavigation) => set({ stopNavigation }),
        setNormalizedForm: (normalizedForm) => set({ normalizedForm }),
        setForm: (form) => set({ form }),
        setDraftSchema: (draftSchema) => set({ draftSchema }),
        setTask: (task) => set({ task }),
        setCurrentStep: (currentStep) => set({ currentStep }),
        setInspection: (inspection) => set({ inspection }),
        setCurrentSectionStep: (currentSectionStep) =>
          set({ currentSectionStep }),
        resetStore: () => {
          set(() => ({
            ...defaultValues,
          }));
        },
        setCompleted: (completed) => set({ completed }),
        setIsLoadingForm: (isLoadingForm) => set({ isLoadingForm }),
      })),
      {
        name: 'web-form',
      }
    )
  );
