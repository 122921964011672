import useTranslation from 'next-translate/useTranslation';

import { ERROR_MESSAGES } from '@/constants/Errors';
import useDeleteForm from '@/queries/useDeleteForm';
import { useFormStorage } from '@/stores/forms';
import { useModalsStorage } from '@/stores/modals';

import { ActionModals } from '@/types';

const useFormModals = () => {
  const { currentTemplate, setCurrentTemplate } = useFormStorage();
  const { setIsModalOpen } = useModalsStorage();
  const { t } = useTranslation('common');
  const { deleteForm, isDeletingForm } = useDeleteForm();

  const handleDeleteForm = (formId: string) => {
    deleteForm(formId);
    setCurrentTemplate(null);
    setIsModalOpen(false);
  };

  const modals: ActionModals = {
    FORM_DELETE: {
      children: (
        <div className='flex flex-col items-center'>
          <p>
            {t('delete_form_confirm_before')}
            <span className='text-red-500'>
              {t('delete_form_confirm_red_text')}
            </span>
            ?
          </p>
          <p>{t('delete_form_confirm_warning')}</p>
        </div>
      ),
      onConfirm: () => {
        handleDeleteForm(currentTemplate?.id ?? '');
      },
      isSubmitting: isDeletingForm,
      onClose: () => {
        setIsModalOpen(false);
        setCurrentTemplate(null);
      },
    },
    FORM_NAME_ALREADY_EXISTS: {
      children: (
        <div className='flex flex-col items-center'>
          <p>{t(ERROR_MESSAGES.form_name_already_exists.title)}</p>
          <p>{t(ERROR_MESSAGES.form_name_already_exists.description)}</p>
        </div>
      ),
      onClose: () => {
        setIsModalOpen(false);
        setCurrentTemplate(null);
      },
    },
  };

  return {
    modals,
  };
};

export default useFormModals;
