import useArchiveModals from './useArchiveModals';
import useCertificationModals from './useCertificationModals';
import useClaimFoldersModals from './useClaimFoldersModals';
import useClaimsModals from './useClaimsModals';
import useEmailModals from './useEmailModals';
import useEmailTasksModals from './useEmailTasksModals';
import useErrorModals from './useErrorModals';
import useFileModals from './useFileModals';
import useFormEditorModals from './useFormEditorModals';
import useFormModals from './useFormModals';
import useInspectionsModals from './useInspectionsModals';
import useReportsModals from './useReportsModals';
import useUsersModals from './useUsersModals';
import useWebInspectionModals from './useWebInspectionModals';
import useWorkflowsFoldersModals from './useWorkflowsFoldersModals';
import useWorkflowsModals from './useWorkflowsModals';

const useModals = () => {
  const { modals: workflowModals } = useWorkflowsModals();
  const { modals: workflowsFoldersModals } = useWorkflowsFoldersModals();
  const { modals: webInspectionModals } = useWebInspectionModals();
  const { modals: inspectionsModals } = useInspectionsModals();
  const { modals: claimsFoldersModals } = useClaimFoldersModals();
  const { modals: claimsModals } = useClaimsModals();
  const { modals: emailTasksModals } = useEmailTasksModals();
  const { modals: emailModals } = useEmailModals();
  const { modals: usersModals } = useUsersModals();
  const { modals: archiveModals } = useArchiveModals();
  const { modals: errorModals } = useErrorModals();
  const { modals: reportModals } = useReportsModals();
  const { modals: certificationModals } = useCertificationModals();
  const { modals: formModals } = useFormModals();
  const { modals: formEditorModals } = useFormEditorModals();
  const { modals: fileModals } = useFileModals();

  return {
    modals: {
      ...workflowModals,
      ...workflowsFoldersModals,
      ...webInspectionModals,
      ...inspectionsModals,
      ...claimsFoldersModals,
      ...claimsModals,
      ...emailTasksModals,
      ...emailModals,
      ...usersModals,
      ...archiveModals,
      ...errorModals,
      ...reportModals,
      ...certificationModals,
      ...formEditorModals,
      ...formModals,
      ...fileModals,
    },
  };
};

export default useModals;
