/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inspection } from '@/types';

export const buildDefaultStepState = (inspection: Inspection) => {
  const stepState = inspection.steps?.map(() => ({
    currentSection: 0,
  }));
  return stepState;
};

export const getFileType = (fileType: string) => {
  const [prefix, suffix] = fileType.split('/');
  if (prefix === 'image') return 'image';

  if (suffix === 'pdf') return 'pdf';

  if (prefix === 'video') return 'video';

  return 'unknown';
};

export const buildCertificationQueue = (
  taskId: any,
  inspectionId: any,
  uploadedFiles: any
) => {
  return uploadedFiles.map((uploadedFile: any) => {
    return {
      status: 'queued',
      certification_data: {
        description: 'No Info',
        download_available: false,
        file: uploadedFile,
        title: 'No Info',
        type: getFileType(uploadedFile.type),
        workflow: taskId,
        category: 'file',
        inspection_id: inspectionId,
        create_inspection: false,
      },
    };
  });
};

export const buildFormPayload = (
  denormalizedSchema: any,
  inspectionId: number
) => {
  return {
    form_data: {
      user_id: 10,
      inspection: inspectionId,
      schema: denormalizedSchema,
    },
    envelope: {
      signatures: [],
    },
  };
};
