import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { ERROR_MESSAGES } from '@/constants/Errors';
import { useInspectionsStore } from '@/stores/inspections';
import { useModalsStorage } from '@/stores/modals';
import { useWorkflowsStorage } from '@/stores/workflows';

import { ErrorResponse } from '@/types';

const createChildrenWorkflow = async (inspectionId: number) => {
  try {
    await api.post(API_ENDPOINTS.CREATE_CHILDREN_WORKFLOW(inspectionId));
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response?.status === 422) {
      throw {
        title: ERROR_MESSAGES.user_already_assigned_workflow.title,
        message: ERROR_MESSAGES.user_already_assigned_workflow.description,
      };
    }

    throw error;
  }
};

export default function useCreateChildrenWorkflow() {
  const { t } = useTranslation('common');
  const { setCurrentWorkflow } = useWorkflowsStorage();
  const { setIsModalOpen } = useModalsStorage();
  const { setIsSubmitting, refetch } = useInspectionsStore();
  const { mutate, isLoading, error, isSuccess, reset } = useMutation(
    ['create-children-workflow'],
    createChildrenWorkflow,
    {
      onSuccess: async () => {
        setIsSubmitting(false);
        toast.success(t('workflows-page.multi-create-success'));
      },
      onError: (error: ErrorResponse) => {
        if (error.title && error.message) {
          return;
        }
        setIsSubmitting(false);
        toast.error(t('workflows-page.save-error'));
      },
      onSettled: () => {
        setCurrentWorkflow();
        setIsModalOpen(false);
        refetch && refetch();
      },
    }
  );

  return {
    isCreatingChildrenWorkflow: isLoading,
    error,
    createChildrenWorkflow: mutate,
    isCreateSuccess: isSuccess,
    resetChildrenWorkflowCreate: reset,
  };
}
